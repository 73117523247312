import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../../../app/providers/StoreProvider'
import { TenderFiltersProps } from '../../../../shared/lib/globalTypes/tenderSearch'
import { FoundTenders } from '../types/FoundTendersSchema'
import { getTenderFilterdata } from '../../../../features/TenderFilter'

interface Filters extends Omit<TenderFiltersProps, 'isSearching'> { }

interface FilterProps extends Filters {
    pageNumber: number
    perPage: number
}

export const searchTenders = createAsyncThunk<
    FoundTenders,
    FilterProps,
    ThunkConfig<string>
>('foundTenders/searchTenders', async (data, thunkApi) => {
    const { extra, rejectWithValue, getState } = thunkApi

    const filter_data = getTenderFilterdata(getState())
    try {
        const response = await extra.api.get<FoundTenders>(
            '/tenders-api/search',
            {
                params: {
                    ...filter_data,
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('error')
    }
})

export const searchTendersInfinite = createAsyncThunk<
    FoundTenders,
    FilterProps,
    ThunkConfig<string>
>('foundTenders/searchTendersInfinite', async (data, thunkApi) => {
    const { extra, rejectWithValue, getState } = thunkApi

    const filter_data = getTenderFilterdata(getState())
    try {
        const response = await extra.api.get<FoundTenders>(
            '/tenders-api/search',
            {
                params: {
                    ...filter_data,
                    pageNumber: data.pageNumber - 1,
                    perPage: data.perPage
                }
            }
        )
        if (!response.data) {
            throw new Error()
        }
        return response.data
    } catch (e: any) {
        return rejectWithValue('error')
    }
})
