import { Tag } from 'antd'
import parse from 'html-react-parser'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { Theme, useTheme } from '../../../../app/providers/ThemeProvider'
import { ReactComponent as ArrowRight } from '../../../../shared/assets/icons/arrow-right.svg'
import { ReactComponent as ArrowRightDark } from '../../../../shared/assets/icons/arrow-right_dark.svg'
import { ReactComponent as CalendarIcon } from '../../../../shared/assets/icons/calendar_icon.svg'
import { ReactComponent as CalendarIconDark } from '../../../../shared/assets/icons/calendar_icon_dark.svg'
import { ReactComponent as ClockIcon } from '../../../../shared/assets/icons/clock-circle.svg'
import { ReactComponent as ClockIconDark } from '../../../../shared/assets/icons/clock-circle_dark.svg'
import { ReactComponent as CoinIcon } from '../../../../shared/assets/icons/coin.svg'
import { ReactComponent as CoinIconDark } from '../../../../shared/assets/icons/coin_dark.svg'
import { classNames } from '../../../../shared/lib/classNames/classNames'
import { getFormattedDate } from '../../../../shared/lib/helpers/getFormattedDate'
import { CountdownTime } from '../../../../shared/ui/Countdown/Countdown'
import { HStack } from '../../../../shared/ui/Stack'
import { FoundTenderItem } from '../../../FoundTenders/model/types/FoundTendersSchema'
import { TenderItem } from '../../model/types/TendersSchema'
import './custom-scrollbar.css'
import cls from './TenderCard.module.scss'

interface TenderCardProps {
    className?: string
    tenderItem: TenderItem | FoundTenderItem
    index: number
}

const TenderCard = memo((props: TenderCardProps) => {
    const { className, tenderItem } = props
    const { t } = useTranslation()
    const { theme } = useTheme()
    const isLigth = theme === Theme.LIGHT

    return (
        <div className={classNames(cls.TenderCard, {}, [className])}>
            <HStack align="start" justify="between">
                <div className={cls.tender_number}>
                    {t('Номер тендера')}:{' '}
                    <Tag color="processing">
                        {tenderItem.number
                            ? parse(`${tenderItem.number}`)
                            : t('Нет номера')}
                    </Tag>
                </div>

                <HStack>
                    <Tag color="red">
                        <p>{t('До завершения')}: </p>{' '}
                    </Tag>

                    <CountdownTime date={tenderItem.deadline} />
                </HStack>
            </HStack>

            {tenderItem.request_type && (
                <Tag color="green">{parse(`${tenderItem.request_type}`)}</Tag>
            )}
            {tenderItem.organization && (
                <div className={cls.tender_title}>
                    {parse(`${tenderItem.organization}`)}
                </div>
            )}

            <div className={cls.tender_description}>
                {parse(`${tenderItem.title}`)}
            </div>
            <div className={cls.sections}>
                <section className={cls.section}>
                    <div className={cls.tender_info}>
                        <div
                            className={`${cls.info_item} ${cls.info_item_small}`}
                        >
                            {isLigth ? <CalendarIcon /> : <CalendarIconDark />}
                            <div>
                                {t('Дата завершения')}:{' '}
                                {parse(
                                    `${getFormattedDate(tenderItem.deadline)}`
                                )}
                            </div>
                        </div>
                        <div className={cls.info_item}>
                            {isLigth ? <CoinIcon /> : <CoinIconDark />}
                            <div>
                                {t('Дата публикации')}:{' '}
                                {`${getFormattedDate(tenderItem.publish_date)}`}
                            </div>
                        </div>
                    </div>
                    <div className={cls.tender_info}>
                        {tenderItem.planned_amount && (
                            <div
                                className={`${cls.info_item} ${cls.info_item_small}`}
                            >
                                {isLigth ? <CoinIcon /> : <CoinIconDark />}

                                {!tenderItem.planned_amount ? (
                                    ''
                                ) : (
                                    <div>
                                        {t('Сумма')}:{' '}
                                        <b>
                                            {parse(
                                                `${tenderItem.planned_amount?.toLocaleString(
                                                    'ru-RU'
                                                )}`
                                            )}
                                              с.
                                        </b>
                                    </div>
                                )}
                            </div>
                        )}

                        {tenderItem.guarantee_provision_duration && (
                            <div className={cls.info_item}>
                                {isLigth ? <ClockIcon /> : <ClockIconDark />}

                                <div className={cls.info_with_blue_text}>
                                    {t('Срок погашения')}:{' '}
                                    {tenderItem.guarantee_provision_duration}
                                </div>
                            </div>
                        )}
                        {tenderItem.guarantee_provision_size && (
                            <div className={cls.info_item}>
                                {isLigth ? <ClockIcon /> : <ClockIconDark />}

                                <div className={cls.info_with_blue_text}>
                                    {t('ГОКЗ')}:{' '}
                                    {tenderItem.guarantee_provision_size}
                                </div>
                            </div>
                        )}
                    </div>
                </section>
                <section>
                    <div className={cls.link}>
                        <a
                            href={tenderItem.href}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Ссылка')}
                        </a>
                        {isLigth ? <ArrowRight /> : <ArrowRightDark />}
                    </div>
                </section>
            </div>
            {tenderItem.lots?.length > 0 && (
                <div className={cls.card_footer}>
                    <div className={cls.tender_lot_name}>
                        <div>{t('Лот(ы)')}:</div>
                        <div className={cls.blue_text}>
                            {tenderItem.lots?.length > 2 ? (
                                <SimpleBar
                                    autoHide={false}
                                    style={{
                                        height: 100,
                                        width: 300,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <div
                                        className={classNames(cls.list, {}, [])}
                                    >
                                        {tenderItem.lots?.map((lot, i) => (
                                            <div key={i}>
                                                - {parse(lot.name)}
                                            </div>
                                        ))}
                                    </div>
                                </SimpleBar>
                            ) : (
                                <div className={classNames(cls.list, {}, [])}>
                                    {tenderItem.lots?.map((lot, i) => (
                                        <div key={i}>{parse(lot.name)}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* <div className={cls.show_all_lots}>
                    <a href="#">Посмотреть все лоты</a>
                </div>{' '} */}
                </div>
            )}
        </div>
    )
})

export default TenderCard
