import { Tag } from 'antd'
import Countdown, {
    CountdownRendererFn,
    CountdownRenderProps
} from 'react-countdown'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from 'react-responsive'
interface Props {
    date: Date
}

export const CountdownTime = ({ date }: Props) => {
    const isMobile = useMediaQuery({ maxWidth: 1200 })
    const { t } = useTranslation()

    const renderer: CountdownRendererFn = ({
        days,
        hours,
        minutes,
        seconds,
        completed
    }: CountdownRenderProps) => {
        if (completed) {
            // Отсчет завершен
            return <Tag color="red">Просрочено</Tag>
        } else {
            // Функция для получения правильного склонения
            const declineWord = (
                number: number,
                words: [string, string, string]
            ) => {
                const cases = [2, 0, 1, 1, 1, 2]
                return words[
                    number % 100 > 4 && number % 100 < 20
                        ? 2
                        : cases[Math.min(number % 10, 5)]
                ]
            }

            // Форматирование времени с учетом склонений
            const formatTime = (
                days: number,
                hours: number,
                minutes: number,
                seconds: number
            ) => {
                const daysText =
                    days > 0
                        ? `${days} ${declineWord(days, [
                              t('день'),
                              t('дня'),
                              t('дней')
                          ])}`
                        : ''
                const hoursText =
                    hours > 0
                        ? `${hours} ${declineWord(hours, [
                              t('час'),
                              t('часа'),
                              t('часов')
                          ])}`
                        : ''
                const minutesText =
                    minutes > 0
                        ? `${minutes} ${declineWord(minutes, [
                              t('минута'),
                              t('минуты'),
                              t('минут')
                          ])}`
                        : ''
                const secondsText =
                    seconds > 0
                        ? `${seconds} ${declineWord(seconds, [
                              t('секунда'),
                              t('секунды'),
                              t('секунд')
                          ])}`
                        : ''

                return [daysText, hoursText, minutesText, secondsText]
                    .filter(Boolean)
                    .join(' ')
            }

            if (isMobile) {
                // Формат для мобильных устройств
                return (
                   <span style={{ color: '#206EBC' }}>
                        {days}
                        {t('д')}:{hours}
                        {t('ч')}:{minutes}
                        {t('м')}:{seconds}
                    </span>
                )
            }

            // Формат для десктопов
            return (
                <span style={{ color: '#206EBC' }}>
                    {formatTime(days, hours, minutes, seconds)}
                </span>
            )
        }
    }

    return <Countdown date={new Date(date)} renderer={renderer} />
}
