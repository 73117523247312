import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfig } from "../../../../app/providers/StoreProvider";
import { fetchUserTags } from "../../../../features/Tags/model/service";
import { USER_TOKEN_KEY } from "../../../../shared/lib/const/localstorage";
import { UserActions } from "../slices/UserSlice";
import { RefreshTokenResponse } from "../types/UserSchema";

export const fetchRefreshToken = createAsyncThunk<RefreshTokenResponse, void, ThunkConfig<string>>(
    'user/refreshToken',
    async (_, thunkApi) => {
        const { extra, rejectWithValue, dispatch } = thunkApi
        try {
            const response = await extra.api.post<RefreshTokenResponse>(`/auth/renew_token`, {
                userToken: localStorage.getItem(USER_TOKEN_KEY)
            });

            dispatch(UserActions.setAuth(true))
            dispatch(fetchUserTags())
            localStorage.setItem(USER_TOKEN_KEY, response.data.usertoken)
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.message);
        } finally {
            dispatch(UserActions.initAuth())
        }
    },
);
